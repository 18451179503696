// @ts-strict-ignore
import React from "react"

import Alert from "@kiwicom/orbit-components/lib/Alert"
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Hide from "@kiwicom/orbit-components/lib/Hide"
import Separator from "@kiwicom/orbit-components/lib/Separator"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import InformationCircle from "@kiwicom/orbit-components/lib/icons/InformationCircle"

import { MultilineText } from "components/generic/multiline-text"
import JourneyIllustration from "components/journey-illustration"

import { Leg } from "types/leg"
import { Tickets } from "types/pass"

import { getLinkifiedStopReplacementDescription } from "utils/disruption-utils"
import { getScheduledTransitLeg } from "utils/pass-utils"

interface SelectedJourneyData {
  legs: Array<Leg>
  tickets?: Tickets
  price: number
}

const SelectedJourney = ({ legs, tickets, price }: SelectedJourneyData) => {
  const scheduledTransitLeg = getScheduledTransitLeg(legs)
  const scheduledLegOrigin = scheduledTransitLeg.origin
  const preBookedOnly = scheduledLegOrigin.preBookedOnly
  const minutesBeforeDeparture = scheduledLegOrigin.bookingCutOffMins

  const stopReplacementLocations = []
  legs.forEach((leg) => {
    if (leg.origin.stopReplacement) {
      stopReplacementLocations.push(leg.origin)
    }
    if (leg.destination.stopReplacement) {
      stopReplacementLocations.push(leg.destination)
    }
  })

  return (
    <Stack spacing="large">
      {stopReplacementLocations.map((location) => (
        <Alert
          key={`${location.id}-${location.stopReplacement.originalLocationId}-${location.stopReplacement.replacementLocationId}`}
          title={"Temporary Stop"}
          icon={<InformationCircle />}
        >
          <MultilineText
            dangerouslySetInnerHTML={{
              __html: getLinkifiedStopReplacementDescription(location),
            }}
          />
        </Alert>
      ))}
      <Stack
        direction="column"
        justify="between"
        largeMobile={{ direction: "row-reverse" }}
      >
        <Hide on={["mediumMobile", "smallMobile"]}>
          <Heading type="title2">£{(price / 100).toFixed(2)}</Heading>
        </Hide>
        <JourneyIllustration
          legs={legs}
          tickets={tickets}
          cancelled={false}
          detailedView
          adminView={false}
        />
      </Stack>
      {preBookedOnly && (
        <Stack spacing="medium">
          <Separator />
          <Stack direction="row" spacing="small" align="center">
            <InformationCircle color="secondary" />
            <Text type="secondary">
              Journey must be booked at least {minutesBeforeDeparture} minutes
              before the scheduled departure time, otherwise the bus will not
              call at this stop
            </Text>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default SelectedJourney
